/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "animaldata",
            "endpoint": "https://l2hm16x0sh.execute-api.ca-central-1.amazonaws.com/dev",
            "region": "ca-central-1"
        },
        {
            "name": "generator",
            "endpoint": "https://bwfg0tovw1.execute-api.ca-central-1.amazonaws.com/dev",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jlp6faiqxrfctbjqw3mqzvaidm.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wxismb6qozhc7ppjy6sh2avuym",
    "aws_content_delivery_bucket": "mayoche-20200907185503-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://dvc9wpssrb1cj.cloudfront.net",
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "animalchoices-dev",
            "region": "ca-central-1"
        }
    ]
};


export default awsmobile;
